<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="7"></user-left>
        </div>
        <div class="col-10">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12">
              <h6>企业认证</h6>
            </div>
            <div class="col-12 mb-4">
                <div class="text-gray-777 mt-3">申请企业认证，不仅享受更多平台服务，还可以申请成为平台服务商，携手共赢。</div>
                <div v-if="tips != ''" class="alert alert-danger p-1 mt-2 d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-lightbulb" viewBox="0 0 16 16">
                        <path
                            d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
                    </svg>
                    <span class="ml-1 mt-1">{{tips}}</span>
                </div>
              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>公司名称</span>
                </div>
                <div class="float-left">
                  <input v-model="companyName" placeholder="请填写公司名称 (与营业执照一致)" class="form-control mr-sm-2 width-300" maxlength="20" type="text"/>
                </div>
              </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>法人姓名</span>
                </div>
                <div class="float-left">
                  <input v-model="legalName" placeholder="请填写法人姓名 (与营业执照一致)" class="form-control mr-sm-2 width-300" maxlength="20" type="text"/>
                </div>
              </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>法人手机号</span>
                </div>
                <div class="float-left">
                  <input v-model="legalMobile" placeholder="请填写法人手机号码" class="form-control mr-sm-2 width-300" maxlength="20" type="text"/>
                </div>
              </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>短信验证码</span>
                </div>
                <div class="float-left">
                 <div class="clearfix">
                    <div class="float-left">
                        <b-form-input type="text" class="rounded width-186" v-model="code" placeholder="填写动态码"
                            maxlength='20' autocomplete='off'></b-form-input>
                    </div>
                    <div class="float-left ml-2">
                      <b-button class="width-110" @click="getCode"
                                :variant="seconds == 0 ? 'success' : 'secondary'"
                                :disabled="seconds > 0">{{seconds == 0 ? '获取动态码' : seconds + 'S后重试'}}
                      </b-button>
                    </div>
                </div>
                </div>
              </div>
             
              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 width-100">
                  <span>营业执照</span>
                </div>
                <div class="float-left">
                      <van-uploader
                          v-model="fileList"
                        preview-size="186"
                        :max-count="1"
                        :max-size="6 * 1024 * 1024"
                        upload-text="上传营业执照"
                        :after-read="afterReadFront">
                        <img
                            v-if="businessLicense != ''"
                            :src="businessLicense"
                            class="width-200"
                        />
                      </van-uploader>
                </div>
              </div>
              <div class="clearfix mt-2">
                <b-button  @click="applyCompany"  variant="success"
                  style="width:186px;margin-left: 116px">申请认证</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
export default {
  name: "certificationcompany",
  data() {
    return {
      tips:'',
      fileList:[],
      businessLicense:"",//营业执照
      companyName:"",//公司名称
      legalMobile:"",//法人手机号码
      legalName:"",//法人名字
      code:"",
      Company:[],
      seconds: 0,
      timer: null,
    };
  },
  methods: {
    verifyMobile() {
      if (this.legalMobile == '') {
        this.$model.toast('请填写法人手机号码',2000)
        return false
      } else {
        if (!this.$test.mobile(this.legalMobile)) {
          this.$model.toast('请填写正确的法人手机号码',2000)
          return false
        }
      }
      return true;
    },
    getCode() {
      if (this.verifyMobile()) {
        this.seconds = 60;
        this.timer = setInterval(() => {
          // 执行加或减函数
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },
    afterReadFront(file) {
      this.$http
          .upload(this.$api.user.upload, "uploadUserFiles", file)
          .then((res) => {
            if (res.code == 1) {
              debugger;
              this.businessLicense = res.result[0].fileUrl;
            }
          });
    },
    getCompany() {
      this.$http.post(this.$api.user.findcompany).then((res) => {
        if (res.code == 1) {
          this.Company=res.result;
          console.log(res.result);
        }
      });
    },
    applyCompany() {
      if(this.companyName.trim() == '') {
        this.$model.toast("请填写公司名称");
        return false;
      }
      if(this.legalName.trim() == '') {
        this.$model.toast("请填写法人姓名");
        return false;
      } else {
        if (!this.$test.trueName(this.legalName)) {
          this.tips = '法人姓名格式错误'
          return false
        }
        if (!this.$test.firstName(this.legalName.substr(0, 1))) {
          this.tips = '法人姓名姓氏有误'
          return false
        }
      }
      if (this.legalMobile == '') {
        this.$model.toast('请填写法人手机号码',2000)
        return false
      }
      if(!this.$test.mobile(this.legalMobile)) {
        this.$model.toast("请填写正确的手机号码");
        return false;
      }

      if(this.code.trim() == '') {
        this.$model.toast("请输入短信验证码");
        return false;
      }
      this.$http.post(this.$api.user.addcompany, {
          businessLicense: this.businessLicense,
          companyName: this.companyName,
          legalMobile: this.legalMobile,
          legalName: this.legalName
        }).then((res) => {
          if (res.code == 1) {
             console.log(res.result)
          }
        });
    },
  },
  created() {
    this.getCompany();
  },
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
</style>
